@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "rainbowBeauty";
  src: url("fonts/RainbowBeautyDemo.otf");
}

*{
  font-family: 'Inter';
  /* font-size: 18px !important; */
  margin: 0;
  padding: 0;
  /* font-weight: 300 !important; */
  /* font-family: 'Lexend', sans-serif !important; */
  /* font-variant: small-caps !important; */
}

body {
  background-color: rgb(245, 245, 245);
}

/* .dark {
  height: 0
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/********** Scrollbar Styles **********/
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #979797;
}

/********** Text Highlight **********/
body ::-moz-selection { /* Firefox */
  /* color: #3C3C3C; */
  /* background: #EAF8FF; */
  color: #515151;
  background: #ECEDEF;
}

body ::selection {
  /* color: #3C3C3C; */
  /* background: #EAF8FF; */
  color: #515151;
  background: #ECEDEF;
}

/* Button Tap Mobile - disabled because of iOS issues */
button,
textarea,
select,
a{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}